import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';

import { useAwaitFullScreenConfirmCancelModal } from '../components/ConfirmCancelModalContext';
import { ModalWrapper } from '../components/ConfirmCancelModalContext/ModalWrapper';
import { useGameLikeWorkspace } from '../components/Game/GameCenter';
import { GamePackSelect } from '../components/Game/GameCenter/GamePackSelect';
import { GamePackCard } from '../components/Game/GamePack/GamePackCard';
import {
  CustomizableGamePackActionSheet,
  GamePackActionSheetButton,
} from '../components/Game/GamePackActionSheet';
import { DeleteIcon } from '../components/icons/DeleteIcon';
import { Loading } from '../components/Loading';
import { apiService } from '../services/api-service';
import { type GamePack } from '../types/game';

type FormData = {
  pack: GamePack | null;
};

function AddGamePackModal(props: {
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async (data: FormData) => {
    if (!data.pack) return;
    const resp = await apiService.gamePack.getGamePacksByCollection('zoomFree');
    const packIds = resp.data.gamePacks.map((pack) => pack.id);
    if (packIds.includes(data.pack.id)) return;
    packIds.push(data.pack.id);
    await apiService.gamePack.updateGamePacksByCollection('zoomFree', packIds);
    props.onConfirm();
  });

  return (
    <ModalWrapper
      containerClassName='w-160'
      borderStyle='gray'
      onClose={props.onCancel}
    >
      <form onSubmit={onSubmit}>
        <div className='w-full h-full p-10 text-white flex flex-col items-center justify-center gap-10'>
          <div className='flex items-center justify-between'>
            <div className='text-2xl font-bold'>
              Add Free Game Pack for Zoom
            </div>
          </div>
          <div className='flex flex-col gap-3'>
            <Controller
              name='pack'
              control={control}
              render={({ field }) => {
                return (
                  <div className='flex items-center gap-2'>
                    <GamePackSelect
                      value={field.value}
                      onChange={(changed) => {
                        if (!changed) return;
                        field.onChange(changed);
                      }}
                      className='w-84'
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className='flex items-center gap-2'>
            <button
              type='button'
              className='btn-secondary w-40 h-10 flex justify-center items-center'
              disabled={!isDirty}
              onClick={() => reset()}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-40 h-10 flex justify-center items-center gap-1'
              disabled={!isDirty || isSubmitting}
            >
              {isSubmitting && <Loading text='' />}
              <div>Save</div>
            </button>
          </div>
        </div>
      </form>
    </ModalWrapper>
  );
}

function GamePackCardActionSheet(props: {
  pack: GamePack;
  onRemove: (pack: GamePack) => void;
}) {
  return (
    <CustomizableGamePackActionSheet
      pack={props.pack}
      stopPropagation
      buttonClassName='invisible group-hover/gamepack-card:visible
      w-7.5 h-7.5 absolute bottom-2 right-2'
      portal
    >
      {(onItemClick) => (
        <>
          <GamePackActionSheetButton
            onClick={() => {
              props.onRemove(props.pack);
              onItemClick();
            }}
            className={`text-red-002`}
          >
            <DeleteIcon />
            <div className=''>Remove</div>
          </GamePackActionSheetButton>
        </>
      )}
    </CustomizableGamePackActionSheet>
  );
}

export function Component() {
  const { data: packs, mutate } = useSWR(
    ['/game-packs', 'zoomFree'],
    async () => {
      const resp = await apiService.gamePack.getGamePacksByCollection(
        'zoomFree'
      );
      return resp.data.gamePacks;
    }
  );
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');

  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const onClick = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <AddGamePackModal
          onCancel={p.internalOnCancel}
          onConfirm={() => {
            p.internalOnConfirm();
            mutate();
          }}
        />
      ),
    });
  };

  const onRemove = async (pack: GamePack) => {
    if (!packs) return;
    const updatedPackIds = packs
      .map((p) => p.id)
      .filter((id) => id !== pack.id);
    await apiService.gamePack.updateGamePacksByCollection(
      'zoomFree',
      updatedPackIds
    );
    mutate();
  };

  return (
    <div className='bg-library-2023-07 p-10 flex flex-col gap-10 text-white'>
      <header className='flex justify-between items-center pb-4'>
        <h1 className='text-3.5xl font-bold'>Free for Zoom</h1>
        <button
          type='button'
          className='btn-primary w-50 h-10'
          onClick={onClick}
        >
          Add Game Pack
        </button>
      </header>
      <main className='flex gap-4'>
        {packs?.map((pack) => (
          <GamePackCard
            key={pack.id}
            gamePack={pack}
            onClick={(pack) => setActiveGamePack(pack)}
            bottomAccessory={
              <GamePackCardActionSheet pack={pack} onRemove={onRemove} />
            }
          />
        ))}
      </main>
    </div>
  );
}
